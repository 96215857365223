// トップページのjs

import "../sass/page-top.scss";

const loading = document.getElementById("loading");

const animation = () => {
  loading.classList.add("loaded");
};

window.addEventListener("load", animation);
